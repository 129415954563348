<template>
  <div class="info_ramtion">
    <el-card>
      <div class="information_table_title">{{ activeName }}</div>
      <div class="info_ramtion_tab">
        <div class="info_ramtion_tab_left">
          <div style="margin-right: 30px">姓名：{{ queryInfo.name }}</div>
          <div>学校：{{ queryInfo.school }}</div>
        </div>
        <div class="info_ramtion_tab_tiem">
          提交时间：{{ queryInfo.createTime }}
        </div>
      </div>
      <el-table
        :header-cell-style="{
          backgroundColor: '#F0F0FF',
          color: '#151515',
          fontWeight: 'bold',
        }"
        class="tab_data"
        stripe
        :data="queryInfo.schoolBooksViews"
      >
        <el-table-column type="index" width="50" align="center" label="序号" />
        <el-table-column label="书籍图片" width="200" align="center">
          <template v-slot="{ row }">
            <el-image :src="row.image" />
          </template>
        </el-table-column>
        <el-table-column
          prop="bookName"
          align="center"
          label="书籍名称"
        ></el-table-column>
        <el-table-column
          prop="heatValue"
          align="center"
          label="价格"
        ></el-table-column>
        <el-table-column
          prop="number"
          align="center"
          label="数量"
        ></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { schoolEnrolment } from "@/api/activity.js";
export default {
  name: "informationTable",
  computed: {
    activeName() {
      return this.$route.query.activeName;
    },
    informationId() {
      return this.$route.query.informationId;
    },
  },
  created() {
    this.seach();
  },
  data() {
    return {
      queryInfo: {
        createTime: "",
        name: "",
        school: "",
        schoolBooksViews: [],
      },
    };
  },
  methods: {
    async seach() {
      const { data: res } = await schoolEnrolment({
        informationId: this.informationId,
      });
      if (res.data) {
        this.queryInfo = Object.assign(res.data[0]);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.info_ramtion {
  width: 100%;
  overflow: auto;
  height: 620px;

  /deep/ .el-image{
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  /deep/
    .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #f8f8ff;
  }

  /deep/
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: #f8f8ff;
  }

  /deep/ .el-card {
    border: none;
  }

  .tab_data {
    margin-top: 40px;
  }

  .information_table_title {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 30px 0 40px 0;
    border-bottom: 1px dashed #e5e5e5;
    text-align: center;
    color: #151515;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .info_ramtion_tab {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .info_ramtion_tab_left {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #000000;
      font-size: 14px;
    }

    .info_ramtion_tab_tiem {
      color: #808080;
      font-size: 12px;
    }
  }
}
</style>